<template>
    <v-container>
      <div class="pa-0" style="max-height:100vh;overflow:hidden;">
        <v-spacer style="height: 100px"></v-spacer>
        <v-card class="mx-auto elevation-5 text-center pa-5" max-width="350"
                style="border-radius:20px;position:relative;top:-50px;"
        >
          <v-card-subtitle class="title black--text pa-1">
            Вход
          </v-card-subtitle>
          <v-card-subtitle class="pa-1">
            Пройдите авторизацию <br/> для доступа к отчётным формам
          </v-card-subtitle>
          <validation-observer ref="form">
            <v-form
                ref="form"
                v-model="valid"
            >
              <validation-provider
                  v-slot="{ errors }"
                  name="логин"
                  rules="required">
                <v-text-field v-model="username" autocomplete="autocomplete"
                              label="Логин" required :error-messages="errors" v-on:keyup.enter="login"></v-text-field>
              </validation-provider>
              <validation-provider
                  v-slot="{ errors }"
                  name="пароль"
                  rules="required">
                <v-text-field v-model="password" autocomplete="autocomplete" label="Пароль"
                              type="password" required :error-messages="errors" v-on:keyup.enter="login"></v-text-field>
              </validation-provider>
              <h1 class="red--text subtitle-2">{{ message }}</h1>
            </v-form>
          </validation-observer>
          <v-card-actions>
            <v-btn
                :loading="loading"
                :disabled="loading"
                @click="login"
                color="red" dark block rounded
                >
              Войти
            </v-btn>


          </v-card-actions>
<!--          <v-card-actions>-->
<!--            <a @click="$router.push({'name': 'forgot_password'})" class="mx-auto">не помню пароль</a>-->
<!--          </v-card-actions>-->
        </v-card>
        <!--            <div class="text-center" style="position:relative;top:-40px">-->
        <!--              <p class="mt-3">У вас нет аккаунта? <span class="red&#45;&#45;text" style="cursor:pointer"-->
        <!--                                                        @click="$router.push({name: 'registration_request'})">Заявка на регистрацию</span>-->
        <!--              </p>-->
        <!--            </div>-->
      </div>
    </v-container>
</template>

<script>
export default {
  name: "Login",
  metaInfo: {
    title: 'Вход'
  },
  data() {
    return {
      username: "",
      password: "",
      message: "",
      valid: false,
      loading: false,
    }
  },
  methods: {
    async login() {
      this.valid = await this.$refs.form.validate()
      if (this.valid) {
        let username = this.username
        let password = this.password
        this.$store.dispatch('login', {username, password}).then(() => {
          this.$router.push('/')
        })
            .catch(err => {
              if (err.response.status === 400) {
                this.message = 'неверный логин или пароль'
                this.username = ""
                this.password = ""
              } else {
                console.log(err)
              }
            })
      }
    },
  },
}
</script>

<style scoped>

</style>
